<template>
  	<div id='card-form'>
		<div class="bg d-none d-sm-none d-md-block">
		</div>
		<div class="container-fluid">
			<div class="row card-form1 align-items-center">
				<div class="col-6 d-none d-sm-none d-md-block">
				</div>
				<div class="col-1 d-none d-sm-none d-md-block">
				</div>
				<div class="col col-md-4">
					<div class="mt-4 d-block d-sm-block d-md-none" >
						<h1>Register and pay:</h1>
						<form>
							<div class="row">
								<div class="col-12">
									<div class="form-input">
										<input type="text" class="form-control" id="name" aria-describedby="emailHelp" placeholder="name">
									</div>
								</div>
								<div class="col-12">
									<div class="form-input">
										<input type="text" class="form-control" id="email" aria-describedby="emailHelp" placeholder="your email">
									</div>
								</div>
								<div class="col-12">
									<div class="form-input">
										<input type="text" class="form-control" id="confirm email" aria-describedby="emailHelp" placeholder="confirm email">
									</div>
								</div>
								<div class="col-12">
									<div class="form-input">
										<input type="password" class="form-control" id="password" aria-describedby="emailHelp" placeholder="password">
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="card-form">
						<h1>Hi, John, enter you credit card info:</h1>
						<form>
							<div class="row">
								<div class="col-12">
									<div class="form-input card-number">
										<img class="cards-logo" src='assets/images/backgrounds/cards.png'>
										<label for="credit-card-number">Credit card number</label>
										<input type="text" class="form-control" id="credit-card-number" aria-describedby="emailHelp" placeholder="Credit card number">
									</div>
								</div>
								<div class="col-6">
									<div class="form-input">
										<label for="credit-card-number">Expiry Date</label>
										<input type="date" class="form-control" id="credit-card-number" aria-describedby="emailHelp" placeholder="Credit card number">
									</div>
								</div>
								<div class="col-6">
									<div class="form-input">
										<label for="credit-card-number">CVC / CVV</label>
										<input type="text" class="form-control" id="credit-card-number" aria-describedby="emailHelp" placeholder="Credit card number">
									</div>
								</div>
								<div class="col-12">
									<div class="form-input">
										<label for="credit-card-number">Country</label>
										<select class="form-control" id="exampleFormControlSelect1">
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option>5</option>
										</select>
									</div>
								</div>
								<div class="col-12">
									<div class="form-input discount">
										<label for="credit-card-number">Enter your discount code box:</label>
										<input type="text" class="form-control" id="credit-card-number" aria-describedby="emailHelp" placeholder="Credit card number">
									</div>
								</div>
							</div>
							<button type="button" class="btn btn-block" @click="openConfimPage">Confirm</button>
						</form>
					</div> 
					
				</div>
			</div>
		</div>
	</div>
</template>
<script>


export default {
	components: {
	},
	data() {
		return {
			reviews: []
		}
	},
	methods: {
		getReviews() {
			this.reviews = [
				{
					img: 'assets/imgs/reviews/img1.png',
					name: 'Angel Bergson',
					des: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ',
				},
				{
					img: 'assets/imgs/reviews/img2.png',
					name: 'Ann Mango',
					des: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ',
				},
				{
					img: 'assets/imgs/reviews/img3.png',
					name: 'Haylie Dokidis',
					des: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ',
				},
				{
					img: 'assets/imgs/reviews/img4.png',
					name: 'Tatiana Geidt',
					des: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ',
				},
				{
					img: 'assets/imgs/reviews/img5.png',
					name: 'Sammy Lawson',
					des: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ',
				},
			]
		},
		openConfimPage() {
			this.$store.commit('SET_USER',true)
			this.$router.push({ path: 'confirmation-page' })
		}
	},
	
	mounted() {
		this.getReviews()
		if ( $(window).width()>700) {
			var windowH = $(window).height()  ;
			$('.card-form1').height(windowH)
		}
	}
}
</script>
<style lang='scss'>

#card-form {
	position: relative;
	background: #E5E5E5;
	.bg {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: url('/assets/images/backgrounds/card-bg.png') no-repeat left center fixed; 
		-webkit-background-size: 75%  100%;
		-moz-background-size: 75%  100%;
		-o-background-size: 75%  100%;
		background-size: 75%  100%;
		background-color: #fff;
		@media (max-width: 700px) {
			background-image: none;
			background: #E5E5E5;
			height: auto !important;
		}
	}
	.card__avatar span {
    background: #293e45;
	}
	.form-group {
		margin-bottom: 27px;
	}
	.form-input {
		margin-bottom: 17px;
	}
	.swiper {
	
		margin: auto;
	}
	h1 {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 600;
		font-size: 36px;
		line-height: 54px;
		text-align: right;
		letter-spacing: 0.01em;
		margin-bottom: 33px;
		color: #1E2D34;
		@media (min-width: 700px) and (max-width: 1400px){
			font-size: 25px;
    	line-height: 32px;
			margin-bottom: 22px;
		}
		@media (max-width: 700px) {
			 font-size: 22px;
				line-height: 30px;
				margin-bottom: 17px;
		}
	}
	button {
		color: #fff !important;
		background: linear-gradient(90deg, #FF7700 0%, #F49624 100%);
		margin-top: 10px;
	}
	form {
		text-align: end;
		label {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			/* identical to box height, or 20px */

			text-align: right;
			letter-spacing: 0.01em;

			color: #383839;
		}
		.form-control {
			background: #F6F7F8;
			border-radius: 6px;
			border-width: 0 ;
			@media (max-width: 700px) { 
				font-size: 14px;
				padding: 5px 12px;
			}
		}
		input {
			text-align: end;
			background: #F6F7F8 !important;
			border-radius: 6px;
			border-width: 0 ;
		}
	}
	.card-form {
		margin-top: 10px;
		@media (min-width: 700px) and (max-width: 1400px){ 
			margin-top: 76px;
		}
		.form-group {
			margin-bottom: 27px;
			@media (max-width: 700px) {
					margin-bottom: 23px;
			}
		}
		.discount {
			@media (max-width: 700px) {
				margin-bottom: 28px;
				margin-top: 10px;
			}
		}
		.btn {
			padding-top: 9px;
			padding-bottom: 9px;
			margin-bottom: 100px;
		}
	}
	.swiper-pagination-bullet  {
	background: #76898F !important;
}
.swiper-pagination-bullet-active {
	background: #FFFFFF !important;
}
.swiper-button-next, .swiper-button-prev {
	top: 100% !important;
	color: #fff !important;
	height: 20px !important;
	@media (min-width: 700px) and (max-width: 1400px){
		top: 97% !important;
	}
}
.swiper-button-next {
	right: 300px;
	@media (min-width: 700px) and (max-width: 1400px){
		right: 159px;
	}
}
.swiper-button-prev {
	left: 300px;
	@media (min-width: 700px) and (max-width: 1400px){
		left: 143px;
	}
}
.swiper-button-next:after, .swiper-button-prev:after {
		font-size: 14px !important;
		font-weight: 600;
}
}
.card-number {
	position: relative;
	.cards-logo {
		position: absolute;
		left: 0;
		top: 5;
	}
}
.slider {
	padding-top: 100px;
	width: 323px;
	max-width: 323px;
	margin: auto;
}
</style>